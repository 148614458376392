<template>
  <b-modal
    id="view-admin-image"
    centered
    title="Admin Profile Image"
    size="lg"
    hide-footer
  >
    <b-row>
      <b-col class="text-center">
        <b-img-lazy style="max-height:80vh;" :src="appUrl + '/uploads/profile-pictures/' + image" fluid alt="Admin Profile Image"></b-img-lazy>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { 
  BModal,
  BRow,
  BCol,
  BImgLazy,

} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";


export default {
  components: {
    BModal,
    BRow,
    BCol,
    BImgLazy,
  },
  props: ["image"],
  data(){
    const { appUrl } = $themeConfig.app;
    return{
      appUrl,
    };
  }
};
</script>