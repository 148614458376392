<template>
  <b-modal
    id="delete-admin"
    centered
    title="Delete User"
    ok-title="Delete"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="deleteAdmin"
  >
    <b-card-text> Are you sure you want to delete the user <strong>{{name}}</strong>? </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BCardText,
  },
  props:["id", "name"],
  emits: ["admin-refresh"],
  methods:{
    deleteAdmin(){
      this.$http
        .get("/admins/delete/"+ this.id)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('admin-refresh')
        })
    }
  }
};
</script>
